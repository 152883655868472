<template>
  <div class="container" style="height: 100vh;">
    <div class="my_top" ref="myTop">
      <div class="played_back" @click="playedBack">
        <img src="../assets/played_left.png" alt="" class="played_left">
        <span class="played_right">我玩过的</span>
      </div>
    </div>
    <div class="played_list" ref="playedList">
      <Strip
        v-for="item in lists"
        class="find_item"
        :key="item.id"
        :item="item"
        route="/page"
        routeios="/pageios"
        :isHome="true"
      ></Strip>
    </div>
  </div>
</template>
<script>
import Strip from "@/components/Strip.vue";
import { mapGetters } from "vuex";
import { askUserHistory } from "@/api";
import { ksort } from "@/utils/ksort";
export default {
  components: {
    Strip
  },
  data() {
    return {
      lists: [
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        },
        {
          id: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          icon: null,
          des: "占位占位占位占位占位占位占位占位",
          tip: "占位",
        }
      ]
    }
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      var param = {
        token: vm.CYBoxUser.loginToken,
        time: Math.floor(Date.now() / 1000),
      };
      param.sign = ksort(param, "qsdhajasfas123sq");
      askUserHistory(param).then(res => {
        if(res.code === 1) {
          var lists = []
          res.data.map(e => {
            switch (e.gb_game_list.type_name) {
              case "传奇":
                e.color = "#f21919"
                break;
              case "仙侠":
                e.color = "#64c60d"
                break;
              case "魔幻":
                e.color = "#8138c0"
                break;
              case "三国":
                e.color = "#f29d23"
                break;
              default:
                break;
            }
            lists.push({
              id: e.gb_game_list.id,
              name: e.gb_game_list.name,
              type: e.gb_game_list.play_name,
              icon: e.gb_game_list.icon,
              des: e.gb_game_list.introduction,
              tip: e.gb_game_list.type_name,
              type_id: e.gb_game_list.type_id,
              color: e.color
            })
          })
          vm.lists = lists
        }
      })
    })
  },
  mounted() {
    if (judgeBigScreen()) {
      this.$refs.myTop.className = "my_top playedTopAndroid";
      this.$refs.playedList.className = "played_list playedListAndroid";
    }
    
  },
  methods: {
    playedBack() {
      store.commit("incrementOut");
      // store.commit("tabbarIn");
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters([
      "CYBoxUser"
    ]),
  },
}
</script>
<style lang="stylus" scoped>
.container
  overflow hidden
.my_top {
  background: url('../assets/playedt.png') center;
  background-size: 100% 100%;
  height: calc(114px + constant(safe-area-inset-bottom));
  height: calc(114px + env(safe-area-inset-bottom));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position relative
  width 100%
}
.played_back
  font-size 16px
  color #fff
  position absolute
  left 10px
  top calc(24px + constant(safe-area-inset-bottom));
  top calc(24px + env(safe-area-inset-bottom));
.played_left
  height 12px
  padding-right 8px
.played_right
  font-size 16px
.played_list
  background #fff
  position absolute
  top calc(56px + constant(safe-area-inset-bottom));
  top calc(56px + env(safe-area-inset-bottom));
  width 96%
  border-radius 5px
  left 2%
  height calc(100vh - (56px + constant(safe-area-inset-bottom)))
  height calc(100vh - (56px + env(safe-area-inset-bottom)))
  overflow scroll
  padding-bottom 30px
</style>